import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataShare } from 'src/app/common/datashare';
import { MainService } from 'src/app/common/main.service';

@Component({
  selector: 'app-bap-terms-conditions',
  templateUrl: './bap-terms-conditions.component.html',
  styleUrls: ['./bap-terms-conditions.component.css']
})
export class BapTermsConditionsComponent {
  constructor(private router : Router, private mainService : MainService ,private dataShare : DataShare, private sanitizer : DomSanitizer ){}
  jsonData:any;
  posts:any;
  version: any;
  introduction : any ;
  touData: any;
  ngOnInit(){
    // let urlArray =window.location.href.split(".com");
    // let locCode= urlArray.length ==1 ?'il':urlArray [0].slice(-2);
   this.mainService.getBapTermsData().subscribe((res: any)=>{
    var doc:any = new DOMParser().parseFromString(res.urlAssetReferences[0].description, "text/html");
    this.version = res.versionId;
    this.dataShare.setVersionId(this.version);
   this.introduction = res.urlAssetReferences[1].description;
   this.touData = res ;
    this.jsonData= this.sanitizer.bypassSecurityTrustHtml(doc.firstChild.innerHTML);
   });
  }
  onTermsAccept(){
    this.dataShare.setTouAccepted(new DatePipe('en-US').transform(new Date(), 'MM-dd-yyyy'));
    this.router.navigate(['/producer/register'])
  }
  onReturnToLogin(){
    this.router.navigate(['/producer/login']);
  }
   
}
