import { Component } from '@angular/core';

@Component({
  selector: 'app-bap-leftcontent',
  templateUrl: './bap-leftcontent.component.html',
  styleUrls: ['./bap-leftcontent.component.css']
})
export class BapLeftcontentComponent {
 listArr = ['Manage your producer information',
 'Accessing quoting and enrollment resources',
 'Complete certification training']
}
