import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HiddenValueCallback } from '@forgerock/javascript-sdk';
import { UserSessionService } from 'src/app/common/user-session/user-session.service';

@Component({
  selector: 'app-bap-hidden-callback',
  templateUrl: './bap-hidden-callback.component.html',
  styleUrls: ['./bap-hidden-callback.component.css']
})
export class BapHiddenCallbackComponent {
  @Input() callback !: HiddenValueCallback;
  @Input() user : any ;
  @Input() authnForm : any;
  @Input() name?: string;

  
  @Output() updatedCallback = new EventEmitter<string>();
  id: any; 
  // callback?: HiddenValueCallback;
  hiddenForm !: FormGroup;
  constructor(private userSession: UserSessionService, private fb: FormBuilder) {

  }
  ngOnInit() {
   //console.log('hidden callback', this.name)
   let callback : any  =  this.callback;
   this.id = callback.payload.input[0].value;
  }

  ngAfterViewInit(){
    this.executeTypingDNAscript(this.userSession.getScript());
  }

  executeTypingDNAscript(script : any){
    // console.log(script)
    var runScript = new Function(script);

    return runScript();
  }
 
  updateValue(event: any): void {
    this.updatedCallback.emit(event.target.value);
  }


}
