import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-bap-footer',
  templateUrl: './bap-footer.component.html',
  styleUrls: ['./bap-footer.component.css']
})
export class BapFooterComponent {
  corpCode: any;
  mtLegalURL:any;
  state:any;
  ilLegalURL : any ;
  okLegalURL:any;
  txLegalURL:any;
  nmLegalURL:any;
  ilDiscrimationURL  : any ;
  okDiscrimationURL :any;
  txDiscrimationURL :any;
  nmDiscrimationURL :any;
  mtDiscrimationURL :any;
  ngOnInit(){
  this.ilLegalURL = environment. BAP_LEGAL_IL
  this.okLegalURL = environment. BAP_LEGAL_OK
  this.txLegalURL = environment. BAP_LEGAL_TX
  this.nmLegalURL = environment. BAP_LEGAL_NM
  this.mtLegalURL = environment. BAP_LEGAL_MT
  this.ilDiscrimationURL = environment. BAP_NON_DISCRIMINATION_IL
  this.okDiscrimationURL  = environment. BAP_NON_DISCRIMINATION_OK
  this.txDiscrimationURL  = environment. BAP_NON_DISCRIMINATION_TX
  this.nmDiscrimationURL  = environment. BAP_NON_DISCRIMINATION_NM
  this.mtDiscrimationURL  = environment. BAP_NON_DISCRIMINATION_MT
  }
}
