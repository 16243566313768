<app-loader [isLoading]="isLoading$ | async"></app-loader>
<div class="flex-center">
  <div class="card tablecard" *ngIf="!verificationDone">
    <div class="card-header padding5 flex-center">
      <div class="card-text flex-center">
        <div>Loading...</div>
      </div>
    </div>
  </div>
  <div class="card tablecard" *ngIf="verificationDone">
    <div class="card-header padding5 flex-center">
      <div
        *ngIf="confirmationType == 'emailSent'; else notSent"
        class="card-text flex-center"
      >
        <p>Thank you!</p>
        <br />
        <span class="text-center">A email verification link has been sent to your email.</span>
      </div>
      <ng-template #notSent>
        <div class="card-text flex-center">
          <p>Link Expired</p>
          <br />
          <span class="text-center"
            >Your verification link has expired. please <a (click)="resendEmail()">request a new
            verification link</a> to confirm your email address.</span
          >
        </div>
      </ng-template>
    </div>
  </div>
</div>
