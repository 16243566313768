import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BapHeaderComponent } from './components/bap-header/bap-header.component';
import { BapFooterComponent } from './components/bap-footer/bap-footer.component';
import { BapLoginComponent } from './components/bap-login/bap-login.component';
import { BapLeftcontentComponent } from './components/bap-leftcontent/bap-leftcontent.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxOtpInputComponent, NgxOtpStatus,
  NgxOtpInputComponentOptions, } from 'ngx-otp-input';
import { AlertModule } from './_alert';
import { BapForgotUseridComponent } from './components/bap-forgot-userid/bap-forgot-userid.component';
import { OtpInputDirective } from './common/otp-input.directive';
import { BapForgotPasswordComponent } from './components/bap-forgot-password/bap-forgot-password.component';
import { BapInformationComponent } from './components/bap-information/bap-information.component';
import { BapTermsConditionsComponent } from './components/bap-terms-conditions/bap-terms-conditions.component';
import { BapHiddenCallbackComponent } from './components/bap-hidden-callback/bap-hidden-callback.component';
import { BapTextCallbackComponent } from './components/bap-text-callback/bap-text-callback.component';
import {LoaderComponent} from "./common/loader/loader.component";
import {VerificationSuccessComponent} from "./common/verification-success/verification-success.component";
import { ConfirmationPageComponent } from './common/confirmation-page/confirmation-page.component';

@NgModule({
  declarations: [
    AppComponent,
    BapHeaderComponent,
    BapFooterComponent,
    BapLoginComponent,
    BapLeftcontentComponent,
    BapForgotUseridComponent,
    OtpInputDirective,
    BapForgotPasswordComponent,
    BapInformationComponent,
    BapTermsConditionsComponent,
    BapHiddenCallbackComponent,
    BapTextCallbackComponent,
    LoaderComponent,
    VerificationSuccessComponent,
    ConfirmationPageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AlertModule,
    AppRoutingModule,
    NgOtpInputModule,
    NgbModule,
    NgxOtpInputComponent,
    BlockUIModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
