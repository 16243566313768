<div class = "inline-container">
    <div class = "text-left">
        <div class = "d-flex mt-2">
            <!-- <div *ngIf = "!message.includes('Typing Biometrics JavaScript API')" class="0" id="callback_">
                <p [ngClass]="message.includes('the way I type') ? 'highlight mt-2' : 'bold mt-2'">{{message}}</p>
            </div> -->
            <div *ngIf="callback?.getMessageType() != '4'" >
              <div *ngIf="callback?.getMessage().includes('enrolled') " class="0" id="callback_">
                <p class="mt-2"><b>{{callback?.getMessage().replaceAll('.','')}}<span> out of 3</span></b></p>
               <!-- <p class="mt-2"><b> You may be required to type the pattern up to three times.</b></p> -->
              </div>
              <div *ngIf="!callback?.getMessage().includes('enrolled') " class="0" id="callback_">
                <p [ngClass]="callback?.getMessage().includes('Try again') ? 'bold mt-2' : 'mt-2'">{{callback?.getMessage()}}</p>
              </div>
               
            </div>
          </div>
    </div>
</div>

<!-- TODO: Need to get placeholder text as directive input from the main component -->
