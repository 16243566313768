import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Config, FRAuth, FRStep } from '@forgerock/javascript-sdk';


import {  CallbackType, NameCallback, PasswordCallback, ChoiceCallback, AttributeInputCallback } from '@forgerock/javascript-sdk';

import { environment } from 'src/environments/environment';
import { DataShare } from 'src/app/common/datashare';
import { AlertService } from 'src/app/_alert';
@Component({
  selector: 'app-bap-forgot-password',
  templateUrl: './bap-forgot-password.component.html',
  styleUrls: ['./bap-forgot-password.component.css']
})
export class BapForgotPasswordComponent implements OnInit{
  showPassword = false;
  bapUserIDForm!: FormGroup;
  bapPasswordForm!: FormGroup;
  ismulti: boolean = false;
  singleVal: string = ''
   loading = false;
 
   choiceCB : any = null
   submitted = false;
   selected: any = 0;
   passwordLenth = 0;
   user: string = '';
   tree: any;
   saveDevice : number = 0;
   previousCallBack :any ;
   NameCallbackSteps : any ;
   backupStep !: FRStep;
   errorpwd: boolean = false;
   isSubmitButtonDisabled1 = true;
   isSubmitButtonDisabled2 = true;
   currentStep: any;
   codeType : any = 'email';
   
   amUrl: any = window.location.href.split(".com").length == 1 ? 'il' : window.location.href.split(".com")[0].slice(-2)
   constructor(
     private formBuilder: FormBuilder,
     private router: Router,
     private dataShare: DataShare,
     private alertService : AlertService
    
   ) {
     console.log('corp', this.amUrl, window.location.href.split(".com"))
     Config.set({
       serverConfig: { baseUrl:   environment.AM_URL,
      
       timeout: 10000 },
       tree: this.tree ? this.tree : environment.FORGOT_PASSWORD_TREE,
       realmPath: environment.REALM_PATH,
   });
 }
 
 
 
   emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
   email: any;
   phone: any;
   loginErrorMsg : any ;
   loginSuccessMsg : any ;
   @ViewChild('moveTop') moveTop !: ElementRef;
   displayErrorMsg : any;
   displaySuccessMsg : any;
   stepIs: string = '';
   isChecked:boolean =false;
   corpCode: any;
   otpCode: any;
   options =  {
    autoClose: true,
    keepAfterRouteChange : true,
    
    }
   mustHaveValidation = [
     { name: 'minAndMax', message: '8-40 characters', selected: false },
     {
       name: 'upperCase',
       message: 'An uppercase letter', selected: false
 
     },
     {
       name: 'lowerCase',
       message: 'A lowercase letter', selected: false
     },
     {
       name: 'number',
       message: 'A number', selected: false
     },
     {
       name: 'specialCharacter',
       message: 'A special character',
       terms: '~ ! @ # $ % ^ & * ( ) [ { }', selected: false
     },
   ];
   cannotHaveValidation = [
     {
       name: 'sequentialCharacters',
       message: 'More than two sequential characters',
       terms: 'Like 123 or ABC', selected: false
     },
     {
       name: 'repeatingCharacters',
       message: 'More than two repeated characters',
       terms: 'Like 111 or AAA', selected: false
     },
     {
       name: 'month',
       message: 'Abbreviations for months',
       terms: 'Like Jan, Feb, or Mar', selected: false
     },
     {
       name: 'day',
       message: 'Abbreviations for days',
       terms: 'Like Mon, Tue, or Wed', selected: false
     },
     {
       name: 'restricted',
       message: 'Restricted terms',
       terms:
         'Appl, Asdf, Basic, Cadam, Demo, Focus, Game, Net, New, Pas, Ros, Sign, Sys, Tso, Valid, Vtam, Log',
       selected: false
     },
     {
       name: 'specialCharacter',
       message: 'A special character',
       terms: '= _ - + ', selected: false
     },
   ];
   ngOnInit() {
        this.currentStep = null
     this.bapUserIDForm = this.formBuilder.group({
       userId: ['', [Validators.required,   Validators.minLength(4),
         Validators.maxLength(40)]],
 
     });
     this.bapPasswordForm = this.formBuilder.group({
 
       password: ['', [
         Validators.required,
         Validators.minLength(6),
         Validators.maxLength(40)
       ]]
     });
     this.nextStep(this.dataShare.step);
     this.isChecked=false;
   }
 
   get userIDForm(): { [key: string]: AbstractControl } {
     return this.bapUserIDForm.controls;
   }
   get passwordForm(): { [key: string]: AbstractControl } {
     return this.bapPasswordForm.controls;
   }
   closeAlert(){

   }
   onSubmit() {
     this.submitted = true;
     // stop here if form is invalid
     if (this.bapUserIDForm.invalid) {
       return;
     }else{
       this.submitted = false;
       this.loading = false;
       
     }
 
   }
   onLoginSubmit() {
     if (this.bapPasswordForm.invalid) {
       return;
     }
 
   }
   onClickSendCodeNext() {
     this.selected = 1;
   }
 
   onReturnToLogin() {
     this.router.navigate(['/producer/login']);
   }
   sendCodeform = this.formBuilder.group({
     sendCodeType: new FormControl('email', Validators.required)
   });
 
   get form() {
     return this.sendCodeform.get('sendCodeType');
   }
 
   onSubmitSendCode() {
     this.selected = 2;
 
   }
   onOtpChange(code: any){
    this.otpCode = code.target.value.replace(/\s/g,"");
   }
   onContinueNext() {
     this.setCallBack(); 
   }
 
   async onSubmitPassword() {
     this.selected = 3;
     this.setCallBack();
     this.backupStep = this.currentStep
     this.currentStep= await FRAuth.next(this.currentStep)
     if(this.currentStep?.payload?.callbacks && this.currentStep?.payload?.callbacks.length > 0 && this.currentStep?.payload?.callbacks[0]?.output[0]?.value && this.currentStep?.payload?.callbacks[0]?.output[1]?.value == "2") {
       this.errorpwd =true;
       let result = this.currentStep?.payload?.callbacks[0]?.output[0]?.value
      result = JSON.parse(result)
       if(result?.policyRequirements[0]?.policyRequirement === "IS_NEW") {
        this.alertService.error(`<span class="circle-exclamation-icon"></span>` + "Password can't have your 10 previously used passwords", this.options)
       }else if(result?.policyRequirements[0]?.policyRequirement=== "CHARS_NOT_ALLOWED"){
        this.alertService.error(`<span class="circle-exclamation-icon"></span>` + "Password cannot contain certain characters", this.options)
       }else if(result?.policyRequirements[0]?.policyRequirement=== "SEQ_CHARS_NOT_ALLOWED") {
        this.alertService.error(`<span class="circle-exclamation-icon"></span>` +'More than two sequential characters are not allowed', this.options)
       }else if(result?.policyRequirements[0]?.policyRequirement=== "CONSECUTIVE_DIGITS_NOT_ALLOWED") {
        this.alertService.error(`<span class="circle-exclamation-icon"></span>` +'Consecutive digits like 111 or 999 not allowed', this.options)
       }else if(result?.policyRequirements[0]?.policyRequirement=== "REPEATED_CHARS_NOT_ALLOWED"){
        this.alertService.error(`<span class="circle-exclamation-icon"></span>` + "Password cannot contain Consecutive characters", this.options)
       }else if(result.policyRequirements[0].params.disallowedFields[0]=== "givenName") {
        this.alertService.error(`<span class="circle-exclamation-icon"></span>` + "Password cannot contain a First Name or Last Name", this.options)
       }else if(result.policyRequirements[0].params.disallowedFields[0]=== "sn") {
        this.alertService.error(`<span class="circle-exclamation-icon"></span>` + "Password cannot contain a First Name or Last Name", this.options)
       }else if(result.policyRequirements[0].params.disallowedFields[0]=== "hcscUserNameSuffix"){
        this.alertService.error(`<span class="circle-exclamation-icon"></span>` + "Password cannot contain username", this.options)
       }else {
        this.alertService.error(result?.policyRequirements[0]?.policyRequirement, this.options)
       }


     }
     this.currentStep.callbacks?.forEach((callback : any, index : any) => {
 
    
      if ((callback.getType() === 'TextOutputCallback')) {
         if(callback?.payload.output[0].value.includes("Successfully Updated Password")){
          this.alertService.success(`<span class="circleCheck-icon"></span>` +callback?.payload.output[0].value,this.options)
          this.onReturnToLogin()
          
           
         }
      }

    })

    
    
    if(this.currentStep.type === "LoginFailure") {
    
      this.alertService.error(`<span class="circle-exclamation-icon"></span>` + this.currentStep.payload?.message,this.options)
    this.onReturnToLogin()
   
     
    } 

     if(this.currentStep?.payload.successUrl) {
       this.errorpwd =true;
       this.currentStep = null
       this.onReturnToLogin();
     }
 
   }
 
   passwordChange(event: any) {
     this.passwordLenth = event.length;
     //Validate minimum length
 
     if (event.length >= 8) {
       this.mustHaveValidation[0].selected = true;
     } else {
       this.mustHaveValidation[0].selected = false;
     }
     //Validate capital latters
     var CapitalLetters = /[A-Z]/g;
     if (event.match(CapitalLetters)) {
       this.mustHaveValidation[1].selected = true;
     } else {
       this.mustHaveValidation[1].selected = false;
     }
     //Validate lowercase latters
     var lowerCaseLetters = /[a-z]/g;
     if (event.match(lowerCaseLetters)) {
       this.mustHaveValidation[2].selected = true;
 
     } else {
       this.mustHaveValidation[2].selected = false;
     }
 
     //Validate number
     var numbers = /[0-9]/g;
     if (event.match(numbers)) {
       this.mustHaveValidation[3].selected = true;
 
     } else {
       this.mustHaveValidation[3].selected = false;
     }
     //Validate Special charector
     var numbers = /[~!@#$%^&*()[{}]/;
     if (event.match(numbers)) {
       this.mustHaveValidation[4].selected = true;
 
     } else {
       this.mustHaveValidation[4].selected = false;
     }
 
     //can't have validations
     //sequentials
     let str = '~ ! @ # $ % ^ & * ( ) [ { }';
 
     for (const character in event) {
       if (
         String.fromCharCode(event.charCodeAt(character) + 1) ===
         event[+character + 1] &&
         !str.includes(event[character]) &&
         String.fromCharCode(event.charCodeAt(character) + 2) ===
         event[+character + 2]
       ) {
         this.cannotHaveValidation[0].selected = true;
       } else {
         this.cannotHaveValidation[0].selected = false;
       }
     }
     if (this.cannotHaveValidation[0].selected = false) {
       for (const character in event) {
         if (
           +event[+character + 1] === +event[character] + 1 &&
           +event[+character + 2] === +event[character] + 2
         ) {
           this.cannotHaveValidation[0].selected = true;
         } else {
           this.cannotHaveValidation[0].selected = false;
         }
       }
     }
     if (/(.)\1\1/.test(event)) {
       this.cannotHaveValidation[1].selected = true;
     } else {
       this.cannotHaveValidation[1].selected = false;
     }
 
     // Check for sequential numerical characters
     var test = (x: any) => !isNaN(x);
     var check = (x: any, y: any, i: any) => x + i === y;
 
     
     for (var i = 0; i < event.length - 2; i++) {
       if (test(event[i])) {
         if (test(event[i + 1]) && test(event[i + 2])) {
           if (check(Number(event[i]), Number(event[i + 1]), 1) &&
             check(Number(event[i]), Number(event[i + 2]), 2)) {
             this.cannotHaveValidation[0].selected = true;
           } else {
           }
         }
       } else if (!test(event[i + 1]) && !test(event[i + 2])) {
         if (check(event.charCodeAt(i), event.charCodeAt(i + 1), 1) &&
           check(event.charCodeAt(i), event.charCodeAt(i + 2), 2)) {
           this.cannotHaveValidation[0].selected = true;
         } 
       }
     }
     let monthsAbb = moment.monthsShort();
 
     if (monthsAbb.find(month => (event.toUpperCase()).match(month.toUpperCase()))) {
       this.cannotHaveValidation[2].selected = true;
     } else {
       this.cannotHaveValidation[2].selected = false;
     }
     let daysAbb = Array.apply(null, Array(7)).map(function (_, i) {
       return moment(i, 'e')
         .startOf('week')
         .isoWeekday(i + 1)
         .format('ddd');
     });
 
     if (daysAbb.find(day => (event.toUpperCase()).match(day.toUpperCase()))) {
       this.cannotHaveValidation[3].selected = true;
     } else {
       this.cannotHaveValidation[3].selected = false;
     }
     let words: string[] =
       ['SYS|LOG|TSO|PAS|NEW|NET|ROS|XXX|SIGN|VTAM|DEMO|ASDF|1234|BASIC|FOCUS|CADAM|VALID|GAME|APPL'];
     words = words[0].split('|');
     if (words.find((restricted: any) => (event.toUpperCase()).match(restricted.toUpperCase()))) {
       this.cannotHaveValidation[4].selected = true;
     } else {
       this.cannotHaveValidation[4].selected = false;
     }
       //Validate Special character
    let str1 = '_  -+  =';
     for (const character in event) {
       if ( str1.includes(event[character]) )
        {
         this.cannotHaveValidation[5].selected = true;
       } else {
         this.cannotHaveValidation[5].selected = false;
       }
     }
     this.isSubmitButtonDisabled1 = !this.mustHaveValidation.every(x => x.selected) ;
     this.isSubmitButtonDisabled2 = this.cannotHaveValidation.some(x => x.selected) ;
   }
   findAccount() {
     this.setCallBack()
 
   }
 
   onClickForgotUser(){
    
   }
   setCallBack() {
     console.log('checking call back')
 
     let urlArray = window.location.href.split(".com");
     this.corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
     let i = 0
     if(this.errorpwd) {
       this.currentStep = this.backupStep
     }
 
     this.currentStep?.callbacks?.forEach((callback: any) => {
       i++
       switch (callback.getType()) {
         case CallbackType.NameCallback: {
          this.NameCallbackSteps = this.currentStep;
           (callback as NameCallback).setName(`${this.bapUserIDForm.value.userId.trim()}`);
           this.user = this.bapUserIDForm.value.userId
 
           break;
         }
 
 
 
         case CallbackType.PasswordCallback: {
           if (callback.payload.output[0].value === "One Time Password") {
             if(this.otpCode==null || this.otpCode==""){
               this.otpCode="000000000";
             }
             (callback as PasswordCallback).setPassword(this.otpCode);
           }
           else {
             (callback as PasswordCallback).setPassword(this.bapPasswordForm.value.password);
           }
 
 
           break;
         }
 
         case CallbackType.StringAttributeInputCallback: {
           console.log('callback', callback, this.currentStep)
           if (callback.payload.input[0].name === "IDToken3") {
             (callback as AttributeInputCallback<any>).setInputValue(this.corpCode.toUpperCase() + '1', 0);
           }
          
 
 
           break;
 
         }
         
 
         default: {
           console.error('Unrecognized callback type.');
           break;
         }
       }
 
 
     })
     if(this.bapPasswordForm.value.password) {
       console.log('test')
     } else{
       this.nextStep(this.currentStep)
     }
 
   }
 
   handleDevice( type : any) {
     this.codeType = type
   }
   
   handleSelection() {
     this.codeType = this.sendCodeform.controls.sendCodeType.value
     this.choiceCallback(this.currentStep, this.codeType);
   }
 
   handleResend() {
    // this.notifyService.showSuccess(`Code sent to ${this.codeType}`, "Success")
     this.choiceCallback(this.choiceCB, this.codeType).then(res=>{
 
       console.log(res);
     });
   }
 
   async choiceCallback(step: FRStep, codeSelected: string) {
     this.choiceCB = step
     step.callbacks?.forEach((callback : any, index : any) => {
 
 
       if (callback.getType() === "ChoiceCallback") {
 
         if(callback.payload.output.length==1 && callback.payload.output.values[0]==="Resend" ){
           (callback as ChoiceCallback).setInputValue(0);
         }
         else if (callback.payload.input[0].name === 'IDToken1') {
           let optedType = codeSelected === 'phone' ? 1 : 0;
 
 
           (callback as ChoiceCallback).setInputValue(optedType);
         }
       }
     });
 
     this.currentStep = await FRAuth.next(step);
    return this.currentStep;
   }
 
   async nextStep(step: FRStep) {

     this.currentStep = await FRAuth.next(step);
    
     if(this.currentStep.type === "LoginFailure") {
      this.onReturnToLogin()
      this.alertService.error(`<span class="circle-exclamation-icon"></span>` + this.currentStep.payload?.message,this.options)
      
     
      
     } else {
     if(this.currentStep?.payload?.callbacks && this.currentStep?.payload?.callbacks.length > 0 && this.currentStep?.payload?.callbacks[0]?.type === "ChoiceCallback") {
       if(this.currentStep?.payload?.callbacks[0]?.output[0].value.includes("The one-time verification code is incorrect")) {
        
      this.alertService.error(`<span class="circle-exclamation-icon"></span>` +this.currentStep?.payload?.callbacks[0]?.output[0].value,this.options)
       
        this.currentStep = step
       }else{
           this.selected = 1;
         }
       if(this.currentStep?.payload?.callbacks[0]?.output[1]?.value?.length === 1 ) {
         this.ismulti = false
         this.singleVal = this.currentStep?.payload?.callbacks[0]?.output[1].value[0]
       } else {
         this.ismulti = true
       }
       this.email = this.currentStep?.payload?.callbacks[0]?.output[1].value[0]?.split(':')[1]
       this.phone = this.currentStep?.payload?.callbacks[0]?.output[1].value[1]?.split(':')[1]
     }else if(this.currentStep?.payload?.callbacks && this.currentStep?.payload?.callbacks.length > 0 && this.currentStep?.payload?.callbacks[0]?.type === "PasswordCallback") {
       this.selected = 3
     }
     this.currentStep.callbacks?.forEach((callback : any, index : any) => {
 
       if ((callback.getType() === 'StringAttributeInputCallback') && index == 0) {
 
       }
       if ((callback.getType() === 'TextOutputCallback')) {
          if(callback?.payload.output[0].value.includes("User is now Locked")){
            this.alertService.error(`<span class="circle-exclamation-icon"></span>` +callback?.payload.output[0].value,this.options)
           this.onReturnToLogin()
          
            
          }
       }
 
     })
 
 
   }
 }
 onReturnBackStep1(){
  this.selected = 0;
  console.log("this.NameCallbackSteps ",this.NameCallbackSteps );
  this.currentStep = this.NameCallbackSteps ;
 }
 onReturnBackStep2(){
  this.selected = 1;
  console.log("this.choiceCB ",this.choiceCB );
  this.currentStep = this.choiceCB ;
 }
 onReturnBackStep3(){
  this.selected = 2;
 }
 

 
}
