<app-loader [isLoading]="loaderService.isLoading$ | async"></app-loader>
<div class="body">
  <div class="row">
    <div class="fiber-grid__col--md-5">
      <input type="radio" class="hideEle" #moveTop />
    </div>
    <ngb-alert
      type="danger"
      class="col-6"
      *ngIf="validateErrorMsg"
      (closed)="closeAlert()"
    >
      <div class="alert-danger">
        <span class="circle-exclamation-icon"></span>
        {{ displayErrorMsg }}
      </div>
    </ngb-alert>
  </div>

  <div *ngIf="selected == 0" class="fiber-grid resp-margin">
    <div class="fiber-grid__row">
      <div
        class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5"
      >
        <article class="baep-hero">
          <ul
            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold"
          >
            <li class="fiber-list__item">
              <a class="fiber-text--color-gray-5" active style="color: #464645"
                >Enter Producer Information</a
              >
            </li>
            <li class="fiber-list__item">
              <a
                class="fiber-text--color-gray-3"
                disabled
                style="color: #dedede"
                >Create Login</a
              >
            </li>
            <li class="fiber-list__item">
              <a
                class="fiber-text--color-gray-3"
                disabled
                style="color: #dedede"
                >Verify Your Email</a
              >
            </li>
          </ul>
        </article>
      </div>

      <div
        class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--offset-md-1 fiber-grid__col--md-5"
      >
        <article class="fiber-box">
          <form
            class="baep-reg-form fiber-form"
            aria-labelledby="Baep_Reg_Form"
            [formGroup]="producerInfoForm"
            (ngSubmit)="onSubmit()"
          >
            <fieldset class="fiber-fieldset fiber-bw-0">
              <div class=" ">
                <span
                  class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary fiber-mr-1"
                >
                </span>
                <span
                  class="fiber-text--weight-normal cursor-pointer fiber-text--size-4 fiber-text--color-blue-primary"
                  (click)="onReturnToLogin()"
                  >Back</span
                ><br />
                <p
                  class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3"
                >
                  Step 1 of 3
                </p>
              </div>

              <h1
                class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 fiber-pb-4"
              >
                Please fill out your producer information.
              </h1>

              <div
                class="fiber-box fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3"
              >
                <div class="form-group">
                  <label for="producerNumber"
                    ><b>Producer Number</b
                    ><span class="fiber-c-red-3">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="producerId"
                    maxlength="9"
                    name="Producer Number"
                    class="form-control mt-2"
                    [ngClass]="{
                      'is-invalid':
                        regForm.producerId.errors &&
                        (regForm.producerId.touched || step1Submitted)
                    }"
                  />
                  <div
                    *ngIf="
                      regForm.producerId.errors &&
                      (regForm.producerId.touched || step1Submitted)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="!regForm.producerId.errors.minlength">
                      {{ getErrMsgForProducerInfo("producerId") }}
                    </div>
                    <div *ngIf="regForm.producerId.errors.minlength">
                      Producer Number should be 9 digits. Please prefix with 0 if your Producer Number is less than 9 digits.
                    </div>
                  </div>
                </div>

                <div class="form-group mt-2">
                  <label for="nationalProducerNumber"
                    ><b>National Producer Number </b
                    ><span class="fiber-c-red-3">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="producerNPN"
                    maxlength="10"
                    name="National Producer Number"
                    class="form-control mt-2"
                    [ngClass]="{
                      'is-invalid':
                        regForm.producerNPN.errors &&
                        (regForm.producerNPN.touched || step1Submitted)
                    }"
                  />
                  <div
                    *ngIf="
                      regForm.producerNPN.errors &&
                      (regForm.producerNPN.touched || step1Submitted)
                    "
                    class="invalid-feedback"
                  >
                    {{ getErrMsgForProducerInfo("producerNPN") }}
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label for="zipCode"
                    ><b>Zip Code</b><span class="fiber-c-red-3">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="zipCode"
                    class="form-control mt-2"
                    name="Zip Code"
                    [ngClass]="{
                      'is-invalid':
                        regForm.zipCode.errors &&
                        (regForm.zipCode.touched || step1Submitted)
                    }"
                  />
                  <div
                    *ngIf="
                      regForm.zipCode.errors &&
                      (regForm.zipCode.touched || step1Submitted)
                    "
                    class="invalid-feedback"
                  >
                    {{ getErrMsgForProducerInfo("zipCode") }}
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="fiber-pt-3 fiber-ta-right">
              <button
                name="baep-reg-continue"
                class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right"
              >
                <span class="fiber-button__text">Continue</span>
              </button>
            </div>
          </form>
        </article>
      </div>
    </div>
  </div>

  <div *ngIf="selected == 1" class="fiber-grid resp-margin">
    <div class="fiber-grid__row">
      <div
        class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5"
      >
        <article class="baep-hero">
          <ul
            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold"
          >
            <li class="fiber-list__item">
              <a
                class="fiber-text--color-gray-3"
                disabled
                style="color: #dedede"
                >Enter Producer Information</a
              >
            </li>
            <li class="fiber-list__item">
              <a class="fiber-text--color-gray-5" active style="color: #464645"
                >Create Login</a
              >
            </li>
            <li class="fiber-list__item">
              <a
                class="fiber-text--color-gray-3"
                disabled
                style="color: #dedede"
                >Verify Your Email</a
              >
            </li>
          </ul>
        </article>
      </div>

      <div
        class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--offset-md-1 fiber-grid__col--md-5"
      >
        <article class="fiber-box">
          <form
            class="baep-createlogin-form fiber-form"
            aria-labelledby="Baep_Create_Login_Form"
            [formGroup]="producerLoginForm"
            (ngSubmit)="onLoginSubmit()"
          >
            <fieldset class="fiber-fieldset">
              <p
                class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4"
              ></p>
              <div class="">
                <span
                  class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary fiber-mr-1"
                >
                </span>
                <span
                  class="fiber-text--weight-normal cursor-pointer fiber-text--size-4 fiber-text--color-blue-primary"
                  (click)="backtoStep1()"
                  >Back</span
                ><br />
                <p
                  class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3"
                >
                  Step 2 of 3
                </p>
              </div>

              <h1
                class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-4"
              >
                Create Your Secure Login.
              </h1>

              <div
                class="fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3"
              >
                <div class="form-group">
                  <label for="userId"
                    ><b>Username</b><span class="fiber-c-red-3">*</span></label
                  >
                  <div class="fiber-grid__row">
                    <div
                      class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--sm-2 fiber-grid__col--md-12"
                    >
                      <div class="input-group mx-auto">
                        <input
                          id="baep-reg-userid"
                          formControlName="userId"
                          class="baep-reg-userid-prefix fiber-input__input fiber-mt-0"
                          name="Username"
                          type="text"
                          [value]=""
                          [ngClass]="{
                            'is-invalid': submitted && regForm2.userId.errors
                          }"
                        />
                        <div
                          *ngIf="submitted && regForm2.userId.errors"
                          class="invalid-feedback"
                        >
                          <div>
                            {{ this.getErrMsgForProducerLogin("userId") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label for="password"
                    ><b>Password</b><span class="fiber-c-red-3">*</span></label
                  >
                  <div class="inner-addon right-addon">
                    <div class="input-group mx-auto">
                      <input
                        type="password"
                        formControlName="password"
                        name="Password"
                        (ngModelChange)="passwordChange($event)"
                        class="form-control mt-1"
                        [ngClass]="{
                          'is-invalid': submitted && regForm2.password.errors
                        }"
                        [type]="showPassword ? 'text' : 'password'"
                      />

                      <div>
                        <i
                          alt="show"
                          class="far eyeShow-icon"
                          (click)="showPassword = !showPassword"
                          [class.hide]="showPassword"
                        ></i>

                        <i
                          alt="hide"
                          class="far fa-eye-slash eye-hide"
                          (click)="showPassword = !showPassword"
                          [class.hide]="!showPassword"
                        ></i>
                      </div>
                      <div
                        *ngIf="submitted && regForm2.password.errors"
                        class="invalid-feedback"
                      >
                        <div>
                          {{ this.getErrMsgForProducerLogin("password") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fiber-grid__row fiber-mb-4">
                  <div class="fiber-grid__col fiber-grid__col--md-6">
                    <p
                      class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2"
                    >
                      Password Must Have
                    </p>
                    <div *ngFor="let must of mustHaveValidation; let i = index">
                      <p class="fiber-text--size-1">
                        <input
                          type="radio"
                          [checked]="must.selected"
                          id="must.name{{ i }}"
                        />
                        {{ must.message }} <br /><span
                          style="margin-left: 12px"
                          >{{ must.terms }}</span
                        >
                      </p>
                    </div>
                  </div>

                  <div class="fiber-grid__col fiber-grid__col--md-6">
                    <p
                      class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2"
                    >
                      Password Cannot Have
                    </p>
                    <div
                      *ngFor="let cant of cannotHaveValidation; let i = index"
                    >
                      <p class="fiber-text--size-1">
                        <input
                          type="radio"
                          [value]="cant.name"
                          [checked]="cant.selected"
                          id="cant.name{{ i }}"
                        />
                        {{ cant.message }} <br /><span
                          style="margin-left: 12px"
                          >{{ cant.terms }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="fiber-pt-3 fiber-ta-right">
              <button
                class="fiber-button fiber-button--size-1 fiber-button--primary fiber-ta-right"
              >
                <span class="fiber-button__text">Create Login</span>
              </button>
            </div>
          </form>
        </article>
      </div>
    </div>
  </div>

  <div *ngIf="selected == 2" class="fiber-grid resp-margin">
    <div class="fiber-grid__row">
      <div
        class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5"
      >
        <article class="baep-hero">
          <ul
            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold"
          >
            <li class="fiber-list__item">
              <a
                class="fiber-text--color-gray-3"
                disabled
                style="color: #dedede"
                >Enter Producer Information</a
              >
            </li>
            <li class="fiber-list__item">
              <a
                class="fiber-text--color-gray-3"
                disabled
                style="color: #dedede"
                >Create Login</a
              >
            </li>
            <li class="fiber-list__item">
              <a class="fiber-text--color-gray-5" active style="color: #464645"
                >Verify Your Email</a
              >
            </li>
          </ul>
        </article>
      </div>

      <div
        class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--offset-md-1 fiber-grid__col--md-5"
      >
        <article class="fiber-box">
          <form
            class="baep-verify-email-form fiber-form"
            aria-labelledby="Baep_Verify_Email_Form"
          >
            <fieldset class="fiber-fieldset">
              <p
                class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4"
              ></p>
              <div class="">
                <p
                  class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3"
                >
                  Step 3 of 3
                </p>
              </div>

              <h1
                class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-3"
              >
                Verify Your email.
              </h1>

              <div class="fiber-box">
                <p class="fiber-text--size-2 fiber-pt-5 fiber-pb-5">
                  This helps us keep your account secure. Click the link in the
                  email we sent to the email associated with your account. You
                  can log in once your email has been verified.
                </p>
                <div
                  class="fiber-flex fiber-flex--direction-responsive fiber-flex--wrap-nowrap fiber-flex--justify-content-space-between fiber-flex--align-items-stretch fiber-flex--gap-support fiber-flex--gap-responsive fiber-flex--basis-0 fiber-flex--divider fiber-flex--block fiber-d-flex"
                >
                  <button
                    (click)="onReturnToLogin()"
                    class="fiber-button fiber-button--size-1 fiber-button--primary fiber-button--block"
                    data-qa-button="Return to Log In"
                  >
                    <span
                      class="fiber-button__text"
                      data-qa-button-label="Return to Log In"
                    >
                      Return to Log In
                    </span>
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </article>
      </div>
    </div>
  </div>
</div>
