import { formatPercent } from '@angular/common';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOtpInput]'
})
export class OtpInputDirective {

  constructor(private el : ElementRef) { }
  @HostListener('input',['$event']) onInputChange(event : any){
    let input = event.target.value.replace(/\s+/g, ''); //Remove existing spaces 

    let inputArr = input.split('')
    if(inputArr.length <= 8){
      let formattedInput = inputArr.join('    '); // Add space after each character

      this.el.nativeElement.value = formattedInput ; // set the formatted Input
    }
 



  }

}
