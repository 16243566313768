<header class="fiber-mt-5 fiber-mb-5 fiber-mr-3">
    <div class="fiber-grid resp-margin">
        <div class="fiber-grid__row mt-2">
<img src="/assets/img/bcbs-5-plan-logo-rgb-states-only.svg" alt="logo" width="25%" />
<div class="v1"></div>
<h6 class="headerTitle ">
    Producer Experience Portal
</h6>
</div>
</div>
</header>
