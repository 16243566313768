
<input type="radio" style="opacity: 0;" #moveTop />
 <div class="row">
    <div class="col-5"></div>
   <div class="col-6">
        <div *ngFor="let alert of alerts" class="{{cssClass(alert)}}">
            
          
            <div [innerHTML]="alert.message"></div>
            <button type="button" class="btn-close" (click)="removeAlert(alert)"></button>
        </div>
    </div>
</div> 