import { Component } from '@angular/core';

@Component({
  selector: 'app-bap-header',
  templateUrl: './bap-header.component.html',
  styleUrls: ['./bap-header.component.css']
})
export class BapHeaderComponent {

}
