import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  script: any;

  constructor() { }
  setScript(script : any){
    this.script = script;
  }
  getScript(){
    return this.script;
  }
}
