import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Config, FRStep } from '@forgerock/javascript-sdk';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormValidation {
  constructor() {}
  patternErrorMessages: { [key: string]: string } = {
    '^[0-9]+$': 'Must contain only numbers',
    '^[a-zA-Z]+$': 'Must contain only characters',
    '^[a-zA-Z0-9]+$': 'Must contain only alphanumeric characters',
    // Add more patterns and messages as needed
  };
  errorMessages: any = {
    required: () => 'is required',
    minlength: (error: any) =>
      `must be at least ${error.requiredLength} characters`,
    maxlength: (error: any) =>
      `must not exceed ${error.requiredLength} characters`,
    pattern: (error:any) => this.getPatternErrorMessage(error.requiredPattern),
  };

  getPatternErrorMessage(pattern: string): string {
    return this.patternErrorMessages[pattern] || 'Invalid pattern';
  }

  getErrorMessage(controlName: string, formGroup: FormGroup): string {
    const control = formGroup?.get(controlName);
    if (control?.errors) {
      const errorKey = Object.keys(control.errors)[0];
      const error = control.errors[errorKey];
      const inputElement = document.querySelector(
        `[formControlName="${controlName}"]`
      );
      const inputName = inputElement
        ? inputElement.getAttribute('name')
        : controlName;

      return this.errorMessages[errorKey]
        ? `${inputName} ${this.errorMessages[errorKey](error)}`
        : `${inputName} Invalid value`;
    }
    return 'Fill all the required fields with valid data';
  }
}
