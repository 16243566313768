import { Component } from '@angular/core';
import { MainService } from '../main.service';
import { AlertService } from 'src/app/_alert';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import { LoaderService } from 'src/app/shared/loader/loader.service';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.css'],
})
export class ConfirmationPageComponent {
  confirmationType: string = '';
  verificationDone: boolean = false;

  userName: string = '';
  verificationCode: string = '';
  corpCode: string = '';
  appName: string = '';
  isLoading$ = this.loaderService.isLoading$;

  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };

  constructor(
    private mainService: MainService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.loaderService.show();
    this.route.queryParams.subscribe((params) => {
      this.userName = params['userName'] || 'defaultUserName';
      this.verificationCode =
        params['verificationCode'] || 'defaultVerificationCode';
      this.corpCode = params['corpCode'] || 'defaultCorpCode';
      this.appName = params['appName'] || 'defaultAppName';
    });
    this.verifyEmail();
  }

  emailSentSuccess() {
    this.confirmationType = 'emailSent';
  }

  verifyEmail() {
    let reqobj: any = {
      userName: this.userName,
      verificationCode: this.verificationCode,
      corpCode: this.corpCode,
      appName: this.appName,
    };

    this.mainService
      .postDataByUrl(reqobj, environment.BAP_VERIFY_Email_API)
      .pipe(
        tap((data: any) => {
          this.loaderService.hide();
          if (data != 'undefined' && data === 'Success') {
            this.router.navigate(['/producer/verification-done']);
          } else {
            this.verificationDone = true;
            this.alertService.error(
              'Verification Failed - Email is not verified.',
              this.options
            );
          }
        }),
        catchError((error) => {
          this.loaderService.hide();
          console.log('error', error);
          this.verificationDone = true;
          const errorMessage = error.error?.errors[0].message
            ? error.error?.errors[0].message
            : error.error?.message
            ? error.error.message
            : 'Verification Failed - Email is not verified.';
          this.alertService.error(errorMessage, this.options);
          return of(null); // Return an Observable with a null value
        })
      )
      .subscribe();
  }

  resendEmail() {
    let reqobj: any = {
      userName: 'OK1.EMP.044244.BAEAdmin',
      resendVerification: 'VNZsq9n0ninF267aml3K6fR81P6Fy1e7',
      appName: 'APP00000430',
      corpCode: 'OK1',
    };

    let url =
      environment.BAP_RESEND_RESEND_Verification_Email +
      '/custom/resend-verification-email';

    this.mainService.postDataByUrl(reqobj, url).subscribe((data: any) => {
      if (data != 'undefined' && data === 'Success') {
        this.emailSentSuccess();
      } else {
        this.alertService.error(
          'Login Failed - Email is not verified.<br/> For assistance, call our Internet Help Desk at 1-888-706-0583',
          this.options
        );
      }
    });
  }
}
