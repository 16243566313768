<app-loader [isLoading]="isLoading$ | async"></app-loader>
<div class="body ">




		<div class="fiber-grid resp-margin">
			<div class="fiber-grid__row">
				<div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
					<app-bap-leftcontent ></app-bap-leftcontent>
				</div>

				<div
					class="border-lft baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
					<article class="fiber-box">

						<fieldset class="fiber-fieldset fiber-bw-0 ">
							<legend
								class="fiber-fieldset__legend fiber-mb-3 fiber-text--size-5 fiber-text--weight-bold">Log
								In
							</legend>
							<!-- <ngb-alert *ngIf="showAlert" type="info alertBox" (closed)="closeAlert()">
								<div class="p-1">

									<h6><b><i class="circleInfo-icon"></i> &nbsp; Producer Experience Portal is Unavailable This Weekend</b></h6>
									<p>We're making routine updates for our system for Producer Experience Portal ):</p>
									<p>You will be not be able to access the account in Producer Experience Portal on your desktop or on mobile device .We apologize for the Inconvenience .</p>
								</div>
							</ngb-alert> -->
							<ngb-alert type="success" *ngIf="successMsg" (closed)="closeAlert()">
								<div class="alertGreenSuccess">
									<span class="circleCheck-icon"></span>
									A one-time verification code has been sent successfully
								</div>
							</ngb-alert>
							<ngb-alert type="danger" *ngIf="loginErrorMsg" (closed)="closeAlert()">
								<div class="alert-danger">
									<span class="circle-exclamation-icon"></span>
									{{displayErrorMsg}}

								</div>
							</ngb-alert>
							<form *ngIf="stepIs === 'login'" class="baep-login-form fiber-form" aria-labelledby="Baep_Login_Form"
								 [formGroup]="loginForm"
								(ngSubmit)="onSubmit()">

								<div class="fiber-box fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">
									<div class="form-group">
										<label for="userId"><b>Username</b></label>
										<input type="text" formControlName="userId" class="form-control mt-1"
											[ngClass]="{ 'is-invalid': submitted && userLoginForm.userId.errors }" />
										<div *ngIf="submitted && userLoginForm.userId.errors" class="invalid-feedback">
											<div *ngIf="userLoginForm.userId.errors.required">User name is required</div>
											<div *ngIf="userLoginForm.userId.errors.minlength">
												User name must be at least 4 characters
											</div>
											<div *ngIf="userLoginForm.userId.errors.maxlength">
												User name must not exceed 40 characters
											</div>
										</div>
									</div>


									<div class="form-group mt-2">
										<label for="password"><b>Password</b></label>
										<div class=" inner-addon right-addon">
											<div class="input-group mx-auto">
												<input type="password" formControlName="password" class="form-control mt-1"
													[ngClass]="{ 'is-invalid':   userLoginForm.password.errors }"
													[type]="showPassword ? 'text' : 'password'" />

													<div>
														<i alt="show" class="far eyeShow-icon"
															(click)="showPassword =!showPassword"
															[class.hide]="showPassword"></i>

														<i alt="hide" class="far fa-eye-slash eye-hide"
															(click)="showPassword =!showPassword"
															[class.hide]="!showPassword"></i>
													</div>



												<div *ngIf="userLoginForm.password.errors"
													class="invalid-feedback">
													<div *ngIf="userLoginForm.password.errors.required">Password is
														required</div>
													<div *ngIf="userLoginForm.password.errors.minlength">
														Password must be at least 8 characters
													</div>
													<div *ngIf="userLoginForm.password.errors.maxlength">
														Password must not exceed 40 characters
													</div>

												</div>

											</div>
										</div>
									</div>
									<br/>
									<div class="form-group">
										<span
											class="fiber-d-flex fiber-flex--direction-row fiber-flex--justify-content-space-between fiber-mb-2">

											<p class="fiber-ta-left">
												<a class="fiber-link fiber-d-block fiber-ta-right fiber-link--size-1"
													 (click)="onClickForgotUser()" data-qa-link=""
													name="baep-forgot-userId">Forgot
													Username</a>
											</p>
											<p class="fiber-ta-right">
												<a class="fiber-link fiber-d-block fiber-ta-right fiber-link--size-1"
													 (click)="onClickForgotPwd()" data-qa-link=""
													name="baep-forgot-password">Forgot Password</a>
											</p>
										</span>
									</div>
								</div>
								<button name="baep-login" [disabled]="loginForm.invalid"
									class="fiber-button fiber-button--size-1 fiber-button--primary fiber-button--block fiber-text--weight-bold fiber-mb-3">
									<span
										class="fiber-icon--size-1 fiber-icon--color-white fiber-icon--name-lock-solid  fiber-mr-1">

									</span>
									<span class="fiber-button__text">Log In</span>
								</button>
							</form>


							<div *ngIf="stepIs === 'verify'">
								<div>
									<span
									class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">
                                    </span>

								<span
								class="fiber-text--weight-normal  cursor-pointer fiber-text--size-4 fiber-text--color-blue-primary" (click)="backToStep1()"
								>Back</span>
								</div>

										<br>
								<form [formGroup]="sendCodeform" class="baep-createlogin-form fiber-form"
									aria-labelledby="Baep_Create_Login_Form">
									<h1
												class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-3">
												Verify your account.</h1>
									<p>
										To keep your information safe, choose one of the option(s)
									</p>
									<div >
										<p><b *ngIf="ismulti">Where should we send your code?</b> <b
												*ngIf="!ismulti">Your code will be sent to: </b></p>
										<span *ngIf="!ismulti">{{singleVal}}</span>
										<div fxLayout="column" *ngIf="ismulti">
											<div *ngIf="displayEmailOption" >

												<input type="radio" value="email" id="email"
													 formControlName="sendCodeType">
												<label for="email"><span style="margin-left: 20px;">Send verification
														code in an email to</span><br />
													<span style="margin-left: 40px;">{{email}}</span></label>

											</div>
											<br>
											<div *ngIf="displayPhnOption" >
												<input type="radio" value="phone" id="phone"
													 formControlName="sendCodeType">
												<label for="phone"><span style="margin-left: 20px;">
													Send verification code in an SMS Text to
													</span><br />
													<span style="margin-left: 40px;">{{phone}}</span></label>
											</div>
											<br>
											<div *ngIf="displayDNAOption" >
												<input type="radio" value="typingDNA" id="typingDNA"
													 formControlName="sendCodeType">
												<label for="phone"><span style="margin-left: 20px;">Prompt me for a
														personalized keyboard typing pattern
													</span><br />
												</label>
											</div>



										</div>

									</div>
									<div fxLayout="row" fxLayoutAlign="end" class="fiber-pt-3 fiber-ta-right">
										<button class="fiber-button fiber-button--size-1 fiber-button--primary  fiber-mb-3 fiber-ta-right" style="border-radius: 50px;"
											(click)="handleSelection()">
											Send Code </button>
									</div>
								</form>
							</div>
							<!-- added for typingdna -->

							<div *ngIf="this.stepIs == 'typingDna'">
								<span
								class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">
								</span>

							<span
							class="fiber-text--weight-normal  cursor-pointer fiber-text--size-4 fiber-text--color-blue-primary" (click)="backToStep2()"
							>Back</span>
								<div class="fiber-br-2 fiber-m-1 fiber-p-4">
									<div class="">
						<form [formGroup]="authnForm" @fadeSlideInOut (ngSubmit)="setCallBack()">
											<ng-container
												*ngFor="let callback of currentStep ? currentStep.callbacks : null; let i = index">

												<span [ngSwitch]="callback.getType()">
													<ng-container *ngSwitchCase="'TextOutputCallback'">


														<app-bap-text-callback [callback]="callback">

														</app-bap-text-callback>
													</ng-container>
													<!-- [authnForm]="hiddenCallbackObject.form" -->
													<ng-container *ngSwitchCase="'HiddenValueCallback'">
														<app-bap-hidden-callback [user]="user" [callback]="$any(callback)"
															[name]="callback?.payload?.input?.[0]?.name"
															(updatedCallback)="$any(callback).setInputValue($event)">

														</app-bap-hidden-callback>
													</ng-container>
												</span>
											</ng-container>
											<input class="form-control" id="idToken3" type="type"
												placeholder="Please enter the above text">
												<input type="checkbox" style="width: 20px;height:20px; margin-top: 10px"
												>
											<span class="fiber-checkbox__label">
												Remember this device for 30 days.</span>
												<br/>
											<button type="submit" class="btn btn-primary mt-2"
												id="loginButton_0">Submit</button>
										</form>
									</div>

								</div>
							</div>
							<!-- ----- code for typing dna save device-------- -->
							<!-- <div *ngIf="askDeviceSave && !showTOU">
								<div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
									<p><b>Do you want to remember this device for 30 days?</b></p>
									<input  type="radio" value="1" id="yes" name="device"
										(change)="typingdnaSaveDevice(1)"><label class="ml-1" for="yes">Yes</label>
									<br>
									<input  type="radio" value="0" id="no" name="device"
										(change)="typingdnaSaveDevice(0)"><label class="ml-1" for="no">No</label>
									<div>
										<button type="button" class="btn btn-primary mt-2"
											(click)="setCallBack()">Submit</button>
									</div>
								</div>
							</div> -->




							<div *ngIf="stepIs === 'otp'"
								class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-12">
								<article class="fiber-box">

									<form class="baep-verify-email-form fiber-form"
										aria-labelledby="Baep_Verify_Email_Form">
										<span
									class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">
                                    </span>
										<span
										class="fiber-text--weight-normal cursor-pointer fiber-text--size-4 fiber-text--color-blue-primary" (click)="backToStep2()"
										>Back</span>
										<fieldset class="fiber-fieldset">

											<p
												class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">


											<h1
												class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-3">
												Verify your account.</h1>

											<div>
												<p>Enter your 8-digit one-time verification code </p>
												<p>Didn't get a code? Click here to <a
														href="javascript:void(0)" (click)="handleResend()"> resend
														it.</a></p>
														<p>
															<!-- <a
															href="javascript:void(0)" (click)="handleReject()"> reject
															it.</a> -->
														</p>

												<div fxLayout="column">
													<div class="form-group">
														<input type="text" maxlength="32" class="form-control w-45" (change)="onOtpChange($event)"  appOtpInput/>
													</div>
                                                </div>
												<input type="checkbox" id="saveDeviceCheck" (change)="rememberDevice($event)" style="width: 20px;height:20px; margin-top: 10px"
															>
														<span class="fiber-checkbox__label">
															Remember this device for 30 days.</span>

											</div>
											<div fxLayout="row" fxLayoutAlign="end" class="fiber-pt-3 fiber-ta-right">
												<button class="fiber-button fiber-button--size-1 fiber-button--primary  col-4" (click)="onContinueNext()"
													style="border-radius: 50px;">
													Submit </button>
											</div>
										</fieldset>


									</form>
								</article>
							</div>
							<!-- <div *ngIf="stepIs === 'touaccepted' &&  !showTOU"
								class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-12">

							</div> -->


							<!-- <div *ngIf="showTOU">
								<h1
									class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 fiber-pb-4">
									Terms of Use.</h1>

								<ng-container>
									<div>


										<br />
										<div class="myTemplate" style="height:400px;padding-left: 80px;overflow: auto">

											<div>
												<p [innerHTML]="jsonData"> </p>
											</div>
										</div>
									</div>

								</ng-container>

								<div class="fiber-pt-3 fiber-ta-right">
									<button name="baep-reg-continue" (click)=" onReturnToLogin()"
										class="fiber-button fiber-button--size-1 fiber-button--inverse  fiber-text--weight-bold fiber-mr-4 fiber-bc-blue-primary fiber-c-blue-primary fiber-bg-gray-0 fiber-mb-3 fiber-ta-right">
										<span class="fiber-button__text">Reject</span>
									</button>
									<button name="baep-reg-continue" (click)="onTermsAccept()"
										class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right">
										<span class="fiber-button__text">I Accept</span>
									</button>
								</div>
							</div> -->

							<div style="margin-top: 20px;" >
								<button name="baep-register" (click)="onCreateClick()" *ngIf="stepIs == 'login'"
									class="fiber-button fiber-button--size-1 fiber-button--inverse fiber-button--block fiber-bc-blue-primary fiber-c-blue-primary fiber-bg-gray-0 fiber-bw-2">
									<span class="fiber-button__text">New User? Register Here</span>
								</button>
							</div>
						</fieldset>



					</article>
				</div>

			</div>

		</div>


</div>
