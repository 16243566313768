import { Injectable } from '@angular/core';
import { Config, FRStep } from '@forgerock/javascript-sdk';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataShare {
  touAccepted: any;
  versionId: any;
  tree: any;
  public step: any;

  constructor() {
    this.step = null;
  }

  setTouAccepted(touAccepted: any) {
    this.touAccepted = touAccepted;
  }

  getTouAccepted() {
    return this.touAccepted;
  }
  setVersionId(versionId: any) {
    this.versionId = versionId;
  }
  getVersionId() {
    return this.versionId;
  }
}
