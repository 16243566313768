import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading.asObservable();
  constructor() {}

  show() {
    this._isLoading.next(true);
    this.stopAfterTimeout(30000);
  }

  hide() {
    this._isLoading.next(false);
  }
  
  private stopAfterTimeout(timeout: number) {
    setTimeout(() => {
      this.hide();
    }, timeout);
  }
}
