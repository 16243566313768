import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BapLoginComponent } from './components/bap-login/bap-login.component';
import { BapForgotUseridComponent } from './components/bap-forgot-userid/bap-forgot-userid.component';
import { BapForgotPasswordComponent } from './components/bap-forgot-password/bap-forgot-password.component';
import { BapTermsConditionsComponent } from './components/bap-terms-conditions/bap-terms-conditions.component';
import { BapInformationComponent } from './components/bap-information/bap-information.component';
import {VerificationSuccessComponent} from "./common/verification-success/verification-success.component";
import { ConfirmationPageComponent } from './common/confirmation-page/confirmation-page.component';

const routes: Routes = [
  {path: '',redirectTo: 'producer/login',pathMatch:'full'},
  {path:'producer/login',component: BapLoginComponent},
  {path:'producer/forgot-user',component:BapForgotUseridComponent},
  {path:'producer/forgot-password',component:BapForgotPasswordComponent},
  {path:'producer/terms-conditions',component:BapTermsConditionsComponent},
  {path:'producer/register',component:BapInformationComponent},
  {path:'producer/verification-done',component:VerificationSuccessComponent},
  {path:'producer/verifyemail',component:ConfirmationPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration :'top', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
